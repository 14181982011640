<template>
  <div class="main-body">
    <div id="nav-white" />
    <div id="news">
      <h1 class="title-md fadeIn">
        Your <span class="text-primary">Knowledge Hub</span> for
        <br class="d-none d-md-block" />
        the Latest News in Logistics
      </h1>

      <div>
        <!-- TABS -->
        <div class="news-container">
          <NewsCard :news="allNews" class="fadeIn" />
        </div>
      </div>
    </div>
    <QuestionContent class="fadeIn" />
  </div>
</template>
<script>
import intersectionObserverMixin from "@/mixins/intersectionObserverMixin";
import NewsCard from "@/components/NewsCard.vue";
import QuestionContent from "@/components/QuestionContent.vue";

export default {
  components: { QuestionContent, NewsCard },
  mixins: [intersectionObserverMixin],
  data() {
    return {
      allNews: [
        {
          img: require("@/assets/images/main/news/n21.jpeg"),
          category: "",
          title:
            "Bandar Enstek, Nilai berpotensi dibangunkan sebagai pusat logistik negara (2024)",
          body: "SEREMBAN: Sebuah kawasan yang khusus bagi pengoperasian syarikat-syarikat logistik di negara ini dicadang untuk dibangunkan di kawasan Bandar Enstek, Nilai di sini.",
          date: "23 Nov 2024",
          time: "5 minutes",
          link: "https://www.utusan.com.my/berita/2024/11/bandar-enstek-nilai-berpotensi-dibangunkan-sebagai-pusat-logistik-negara/",
        },
        {
          img: require("@/assets/images/main/news/n22.jpg"),
          category: "",
          title:
            "Syor pusat logistik negara yang baharu dibina di Nilai, dekat KLIA (2024)",
          body: "NILAI: Menteri Pengangkutan, Anthony Loke, mencadangkan supaya diwujudkan pusat logistik baharu negara di Nilai, Negeri Sembilan, sesuai dengan lokasi berhampiran Lapangan Terbang Antarabangsa Kuala Lumpur (KLIA).",
          date: "23 Nov 2024",
          time: "5 minutes",
          link: "https://www.bharian.com.my/berita/nasional/2024/11/1327663/syor-pusat-logistik-negara-yang-baharu-dibina-di-nilai-dekat-klia",
        },
        {
          img: require("@/assets/images/main/news/n23.jpg"),
          category: "",
          title:
            "Enstek, Nilai Berpotensi Dibangunkan Pusat Logistik Negara — Loke (2024)",
          body: "NILAI, 23 Nov (Bernama) -- Kementerian Pengangkutan mencadangkan kerajaan Negeri Sembilan  menyediakan satu kawasan khusus di Nilai atau Enstek bagi pembinaan pusat logistik baharu di negara ini.",
          date: "23 Nov 2024",
          time: "5 minutes",
          link: "https://www.bernama.com/bm/am/news.php?id=2366699",
        },
        {
          img: require("@/assets/images/main/news/n24.jpg"),
          category: "",
          title:
            "Enstek, Nilai berpotensi dibangunkan pusat logistik negara - Loke (2024)",
          body: "NILAI: Kementerian Pengangkutan mencadangkan kerajaan Negeri Sembilan menyediakan satu kawasan khusus di Nilai atau Enstek bagi pembinaan pusat logistik baharu di negara ini.",
          date: "23 Nov 2024",
          time: "7 minutes",
          link: "https://www.astroawani.com/berita-malaysia/enstek-nilai-berpotensi-dibangunkan-pusat-logistik-negara-loke-497594",
        },
        {
          img: require("@/assets/images/main/news/n25.png"),
          category: "",
          title: "Nilai dicadangkan sebagai pusat logistik negara (2024)",
          body: "BANDAR ENSTEK, 23 November-Kementerian Pengangkutan bercadang untuk membangunkan Nilai termasuk Bandar Enstek sebagai pusat logistik negara. Menteri Pengangkutan, Anthony Loke Siew Fook berkata, perkara itu dilihat mampu menarik minat banyak syarikat logisitik untuk berpangkalan dan menjalankan operasi mereka sekiranya tapak berkenaan dibangunkan kelak.",
          date: "23 Nov 2024",
          time: "2 minutes",
          link: "https://berita.rtm.gov.my/nasional/senarai-berita-nasional/senarai-artikel/nilai-dicadangkan-sebagai-pusat-logistik-negara",
        },
        {
          img: require("@/assets/images/main/news/n26.jpg"),
          category: "",
          title:
            "Transport Ministry proposes new logistics hub in Nilai or Enstek (2024)",
          body: "The Ministry of Transport has proposed the establishment of a dedicated logistics hub in Nilai or Enstek, Negeri Sembilan, to further bolster Malaysia’s logistics sector.",
          date: "23 Nov 2024",
          time: "2 minutes",
          link: "https://thesun.my/malaysia-news/transport-ministry-proposes-new-logistics-hub-in-nilai-or-enstek-LC13330685#google_vignette",
        },
        {
          img: require("@/assets/images/main/news/n27.png"),
          category: "",
          title:
            "Transport ministry proposes logistics hub in Negeri Sembilan (2024)",
          body: "NILAI: The transport ministry has proposed the development of a dedicated logistics hub in Nilai or Enstek, Negeri Sembilan, to bolster Malaysia’s logistics sector.",
          date: "23 Nov 2024",
          time: "5 minutes",
          link: "https://www.freemalaysiatoday.com/category/nation/2024/11/23/transport-ministry-proposes-logistics-hub-in-negeri-sembilan/",
        },
        {
          img: require("@/assets/images/main/news/n28.png"),
          category: "",
          title: "Loke proposes new logistics hub in Nilai or Enstek (2024)",
          body: "NILAI (Nov 23): The Ministry of Transport has proposed the establishment of a dedicated logistics hub in Nilai or Enstek, Negeri Sembilan, to further bolster Malaysia’s logistics sector.",
          date: "23 Nov 2024",
          time: "5 minutes",
          link: "https://theedgemalaysia.com/node/735149",
        },
        {
          img: require("@/assets/images/main/news/n29.png"),
          category: "",
          title: "陆兆福：吸引更多投资  建议森发展国家物流中心 (2024)",
          body: "(芙蓉23日讯) 交通部长陆兆福建议森州政府 在汝来恩斯德镇 (Bandar Enstek) 提供一片土地，以发展成为一个国家物流中心 ，吸引更多物流公司设据点并进行运营.",
          date: "23 Nov 2024",
          time: "5 minutes",
          link: "https://www.sinchew.com.my/?p=6096128",
        },
        {
          img: require("@/assets/images/main/news/n210.png"),
          category: "",
          title: "交长:纾缓巴生港口拥堵 森美兰可献地设物流中心 (2024)",
          body: "(芙蓉23日讯) 交通部长陆兆福建议森州政府献出一片土地，以在汝来恩斯德镇设立一个国家物流中心，以纾缓巴生港口的拥堵情况。",
          date: "23 Nov 2024",
          time: "5 minutes",
          link: "https://www.enanyang.my/%E8%B4%A2%E7%BB%8F%E6%96%B0%E9%97%BB/%E4%BA%A4%E9%95%BF%E7%BA%BE%E7%BC%93%E5%B7%B4%E7%94%9F%E6%B8%AF%E5%8F%A3%E6%8B%A5%E5%A0%B5-%E6%A3%AE%E7%BE%8E%E5%85%B0%E5%8F%AF%E7%8C%AE%E5%9C%B0%E8%AE%BE%E7%89%A9%E6%B5%81%E4%B8%AD%E5%BF%83#google_vignette",
        },
        {
          img: require("@/assets/images/main/news/n2.jpeg"),
          category: "",
          title:
            "Golog, Royal Eficaz jalin kerjasama bangunkan hab halal (2024)",
          body: "KUALA LUMPUR - Royal Eficaz (RE) dan Gobuilders Netsoft Sdn. Bhd. (Golog) menjalin kerjasama strategik dengan menyasarkan pembangunan Pusat Pengedaran Halal Serantau Pintar (RHDC) serta Pelabuhan Darat Serantau Pintar (RHIP) di Bandar Enstek, Negeri Sembilan.",
          date: "19 May 2024",
          time: "5 mins read",
          link: "https://www.kosmo.com.my/2024/05/19/golog-royal-eficaz-jalin-kerjasama-bangunkan-hab-halal",
        },
        {
          img: require("@/assets/images/main/news/n1.jpg"),
          category: "",
          title:
            "Bandar Enstek attracts RM420m new investments, offering 2,000 jobs (2024)",
          body: "SEREMBAN: Bandar Enstek in Nilai has attracted RM420 million in new investments with the entry of four investors in various sectors.",
          date: "20 Feb 2024",
          time: "5 minutes",
          link: "https://thesun.my/malaysia-news/bandar-enstek-attracts-rm420m-new-investments-offering-2000-jobs-AA12120066",
        },
        {
          img: require("@/assets/images/main/news/n4.png"),
          category: "",
          title:
            "JCI Malaysia Sustainable Development Award winners Interview (2022)",
          body: "Did you know that GOBUILDERS NETSOFT SDN BHD is one of the 2022 JCI Malaysia Sustainable Development Award winners? Let's listen to what GOBUILDERS NETSOFT SDN BHD says about the 2022 JCI Malaysia Sustainable Development Award.",
          date: "11 Nov 2022",
          time: "5 mins read",
          link: "https://www.facebook.com/JCIMSDA/videos/interview-with-gobuilders-netsoft-sdn-bhd/1513928505699636/",
        },
        {
          img: require("@/assets/images/main/news/n3.jpg"),
          category: "",
          title:
            "Bandar Enstek berjaya tarik pelaburan baharu RM420 juta (2024)",
          body: "SEREMBAN - Bandar Enstek berjaya menarik pelaburan baharu sebanyak RM420 juta dengan termeterainya perjanjian jual beli TH Properties Sdn. Bhd. bersama empat syarikat iaitu  Educ8 Group, Gobuilders Netsoft, Malindo Airways dan Meta Legends.",
          date: "07 Nov 202",
          time: "5 mins read",
          link: "https://www.kosmo.com.my/2024/02/21/bandar-enstek-berjaya-tarik-pelaburan-baharu-rm420-juta",
        },
        {
          img: require("@/assets/images/main/news/n5.png"),
          category: "",
          title:
            "Uberisation of cold chain network for farmers, fishermen (2022)",
          body: "When Ivan Chin, the founder and CEO of GoLog Malaysia, a cold chain online logistics and delivery platform, first read about the farmers in Cameron Highlands letting their vegetables go to waste, he knew more than the average Malaysian, what was going on behind the scenes.",
          date: "17 Apr 2020",
          time: "5 mins read",
          link: "https://theedgemalaysia.com/article/uberisation-cold-chain-network-farmers-fishermen",
        },
      ],
    };
  },
  mounted() {
    this.setupIntersectionObserver(".fadeIn", 0.2);
  },
};
</script>
