<template>
  <div class="tab-body">
    <b-row>
      <b-col
        v-for="(n, index) in news"
        :key="index"
        cols="12"
        md="6"
        lg="4"
        class="d-flex justify-content-center"
        style="height: auto !important"
      >
        <div class="news-card">
          <a
            :href="n.link"
            target="_blank"
            class="d-flex justify-content-center"
          >
            <div class="new-img">
              <img :src="n.img" />
            </div>
          </a>
          <div class="px-1 mt-2 news-content sm-center">
            <label v-if="n.category">{{ n.category }}</label>
            <a :href="n.link" target="_blank">
              <h5>{{ n.title }}</h5>
            </a>
            <p class="truncate-multiple-lines">
              {{ n.body }}
            </p>
            <div class="date">
              {{ n.date }}
              <div class="circle" />
              {{ n.time }} read
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: {
    news: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style>
.truncate-multiple-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
